/* eslint-disable prefer-template, no-param-reassign */

import _ from "lodash-es";
import moment from 'moment';
import simpleDate from "./simpleDate.js";

// Supports easy parsing of the parameters (value, [decimals], [options])
// ie, both `decimals` and `options` are optional and we determine from the type what to use
function parseArgs(allArgs) {
    if (allArgs.length >= 2 && _.isNumber(allArgs[1])) {
        return {
            value: allArgs[0],
            decimals: allArgs[1],
            options: allArgs[2] || {},
        };
    } else {
        return {
            value: allArgs[0],
            decimals: _.get(allArgs[1], 'decimals'),
            options: allArgs[1] || {},
        };

    }
}

function asCurrency(/* value, [decimals], [options] ={} */) {
    const { value, decimals = 0, options } = parseArgs(arguments);
    const {
        currency = 'GBP',
        na = '-',
    } = options;

    if (typeof value !== 'number' || isNaN(value)) {
        return na;
    }

    return value.toLocaleString(undefined, {
        currency,
        style: 'currency',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
}

const simpleDatePatterns = {
    'en-GB': {
        short: 'DD/MM/YYYY',
        long: 'DD MMMM YYYY',
        iso: 'YYYY-MM-DD',
    },
    'en-IE': {
        short: 'DD/MM/YYYY',
        long: 'DD MMMM YYYY',
        iso: 'YYYY-MM-DD',
    },
    'en-US': {
        short: 'MM/DD/YYYY',
        long: 'MMMM DD, YYYY',
        iso: 'YYYY-MM-DD',
    },
};

/**
 * Returns a simple-date in the format 'DD/MM/YYYY' (short, default) or 'DD MMMM YYYY' (long)
 * @param {Date | string | null | undefined} value A Date or a string
 * @param {'en-GB' | 'en-IE' | 'en-US'} locale
 * @param {*} options
 * @returns
 */
function asSimpleDate(value, locale, options = {}) {
    const {
        format = 'short', // 'short' (31/12/2021) OR 'long' (31 December 2021) OR 'iso' (2021-12-31)
        na = '-', // N/A value: what to return if the value is not a Date object or an ISO string
    } = options;

    if (!locale) {
        throw new Error(`Invalid locale value "${locale}" for asSimpleDate(). It should be 'en-GB', 'en-IE' or 'en-US'.`);
    }

    const isoRegex = /^\d\d\d\d-\d\d-\d\dT\d\d\:\d\d\:\d\d\.\d\d\dZ/;
    const isDate = value instanceof Date || (_.isString(value) && isoRegex.test(value));
    if (!isDate) {
        return na;
    }

    const pattern = simpleDatePatterns[locale][format];

    if (!pattern) {
        throw new Error(`Invalid format value "${format}" for asSimpleDate(). It should be one of: 'short', 'long', 'iso'`);
    }

    return moment(value).utc().format(pattern);
}

// Deprecated, use asSimpleDate instead
function asDate(value, options = {}) {
    const {
        format = 'short', // 'short' (31/12/2021) OR 'long' (31 December 2021)
        na = '-', // N/A value
    } = options;

    const isoRegex = /^\d\d\d\d-\d\d-\d\dT\d\d\:\d\d\:\d\d\.\d\d\dZ/;
    const isDate = value instanceof Date || (_.isString(value) && isoRegex.test(value));
    if (!isDate) {
        return na;
    }

    let pattern = 'short';
    if (format === 'short') {
        pattern = 'DD/MM/YYYY';
    } else if (format === 'long') {
        pattern = 'DD MMMM YYYY';
    } else {
        throw new Error(`Invalid format value "${format}" for asDate(). It should be 'short' or 'long'.`);
    }

    return simpleDate.format(value, pattern);
}

function asDateTime(dateOrTimestamp, options = {}) {
    const {
        na = '-',
        locale,
    } = options;

    const isoRegex = /^\d\d\d\d-\d\d-\d\dT\d\d\:\d\d\:\d\d\.\d\d\dZ/;
    let date;

    if (dateOrTimestamp instanceof Date) {
        date = dateOrTimestamp;
    } else if (_.isString(dateOrTimestamp) && isoRegex.test(dateOrTimestamp)) {
        date = new Date(dateOrTimestamp);
    } else {
        return na;
    }

    return date.toLocaleString(locale);
}

const timestampPatterns = {
    'en-GB': {
        iso: 'YYYYMMDD-HHmm',
        medium: 'DD/MM/YYYY HH:mm',
    },
    'en-IE': {
        iso: 'YYYYMMDD-HHmm',
        medium: 'DD/MM/YYYY HH:mm',
    },
    'en-US': {
        iso: 'YYYYMMDD-HHmm',
        medium: 'MM/DD/YYYY HH:mm',
    },
};

/**
 * Formats a timestamp (Date object) as a string
 * @param {Date | string | null | undefined} value A Date or a string
 * @param {'en-GB' | 'en-IE' | 'en-US'} locale
 * @param {*} options
 * @returns
 */
function asTimestamp(value, locale, options = {}) {
    const {
        format = 'medium', // 'medium' (31/12/2021 09:02) OR 'full' (31/12/2021 09:02:34 +00:00) OR 'iso' (20211231-0902)
        na = '-', // N/A value: what to return if the value is not a Date object or an ISO string
    } = options;

    if (!locale) {
        throw new Error(`Invalid locale value "${locale}" for asTimestamp(). It should be 'en-GB', 'en-IE' or 'en-US'.`);
    }

    const isoRegex = /^\d\d\d\d-\d\d-\d\dT\d\d\:\d\d\:\d\d\.\d\d\dZ/;
    const isDate = value instanceof Date || (_.isString(value) && isoRegex.test(value));
    if (!isDate) {
        return na;
    }

    if (format === 'fromNow') {
        return moment(value).fromNow();
    }

    const pattern = timestampPatterns[locale][format];

    if (!pattern) {
        throw new Error(`Invalid format value "${format}" for asTimestamp(). It should be one of: 'medium', 'fromNow', 'iso'`);
    }

    return moment(value).format(pattern);
}

// Replaces any invalid characters from the filename with a dash -
// See https://learn.microsoft.com/en-us/windows/win32/fileio/naming-a-file#naming-conventions
function asFilename(filename) {
    if (typeof filename !== 'string') {
        throw new Error(`Invalid type for asFilename(). It should be a string.`);
    }

    const safeName = filename.replace(/[\\/:*?"<>|]+/g, '-').trim();
    return safeName;
}

// Strip characters not allowed in HTTP headers (see https://stackoverflow.com/a/75998796 for allowed chars)
function asHTTPHeader(value) {
    return String(value).replace(/[^A-Za-z0-9-_.~!#$&'()*+,/:;=?@[\] ]/g, '');
}

function asNumber(/* value, [decimals], [options] ={} */) {
    const { value, decimals = 0, options } = parseArgs(arguments);
    const {
        na = 'n/a',
    } = options;

    if (typeof value !== 'number' || isNaN(value)) {
        return na;
    }
    return value.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
}

function asPercentage(value, options = {}) {
    const {
        decimals = 2,
        na = 'n/a',
    } = options;

    if (typeof value !== 'number' || isNaN(value)) {
        return na;
    }

    return `${asNumber(value, { decimals, na })}%`;
}

function humanise(value, options = {}) {
    const {
        decimals = 0,
        na = 'n/a',
    } = options;

    if (typeof value !== 'number' || isNaN(value)) {
        return na;
    }

    const thousand = 1000;
    const million = 1000000;
    const billion = 1000000000;
    const trillion = 1000000000000;

    let output = value;
    if (value < thousand) {
        output = value.toFixed(decimals);
    } else if (value >= thousand && value < million) {
        output = (value / thousand).toFixed(decimals) + 'K';
    } else if (value >= million && value < billion) {
        output = (value / million).toFixed(decimals) + 'M';
    } else if (value >= billion && value < trillion) {
        output = (value / billion).toFixed(decimals) + 'B';
    } else if (value >= trillion) {
        output = (value / trillion).toFixed(decimals) + 'T';
    }
    return output;
}

// Returns the singularString if items is 1, otherwise returns pluralString
// Items can be a number or an array (the array length will be used)
function pluralise(items, singularString, pluralString, hideAmount) {
    let amount = items;

    if (_.isArray(items)) {
        amount = items.length;
    }

    if (!Number.isFinite(amount)) {
        return undefined;
    }

    const prefix = hideAmount ? '' : `${asNumber(amount)} `;
    return `${prefix}${amount === 1 ? singularString : pluralString}`;
}


function asTimeFromNow(value) {
    return moment(value).fromNow();
}

export default {
    asCurrency,
    asSimpleDate,
    asTimestamp,
    asDate, // Deprecated, use asSimpleDate instead
    asDateTime, // Deprecated, use asTimestamp instead
    asFilename,
    asHTTPHeader,
    asNumber,
    asPercentage,
    asTimeFromNow,
    humanise,
    pluralise,
};
