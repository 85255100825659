import { Link, useOutletContext } from 'react-router-dom';
import { useState } from 'react';
import { Option, simpleDate } from 'smg-common';
import { Format, Http, Loading, Pagination, Pill, ReactSelect, useFetch } from 'Components';
import { ViewDashboardPageContext } from 'Dashboard3/ViewDashboardPage';

import css from './CampaignBreakdownTable.module.scss';

type SortByOptions = 'maxStudyCreatedDate' | 'campaignName' | 'totalCost' | 'numberOfStudies' | 'evaluatedActivities' | 'totalImpressions' | 'weightedCpm' | 'weightedRoiInStore' | 'weightedRoiEcomm';

interface CampaignBreakdownMetrics {
    campaign: {
        _id: string;
        name: string;
    };
    plan: {
        _id: string;
        name: string;
    };
    maxStudyCreatedDate: string;
    totalCost: number;
    numberOfStudies: number;
    evaluatedActivities: number;
    totalImpressions: number;
    weightedCpm: {
        value?: number;
        count?: number;
    };
    weightedRoiInStore: {
        value?: number;
        count?: number;
    };
    weightedRoiEcomm: {
        value?: number;
        count?: number;
    };
}

const TABLE_HEIGHT = 650;

const CampaignBreakdownTable = () => {
    const { queryParams } = useOutletContext() as ViewDashboardPageContext;

    const [sortBy, setSortBy] = useState<SortByOptions>('maxStudyCreatedDate');
    const [currentPage, setCurrentPage] = useState(1);

    const PAGE_SIZE = 10;

    const controller = new AbortController();
    const [campaignData, isCampaignDataLoading] = useFetch<CampaignBreakdownMetrics[]>(() => (
        Http.get('/dashboard3/campaign-view', { params: queryParams, signal: controller.signal })
    ), [queryParams], { controller });

    return (
        <>
            {/* Filters */}
            <h3 className="mb-3">Campaign breakdown</h3>
            <div className="stack-end" style={{ gap: '1rem' }}>
                <ReactSelect
                    label="Sort by"
                    style={{ minWidth: '200px' }}
                    options={[
                        { label: 'Most recent study', value: 'maxStudyCreatedDate' },
                        { label: 'Campaign name', value: 'campaignName' },
                        { label: 'Cost', value: 'totalCost' },
                        { label: 'No. of studies', value: 'numberOfStudies' },
                        { label: 'Evaluated activities', value: 'evaluatedActivities' },
                        { label: 'Total impressions', value: 'totalImpressions' },
                        { label: 'Weighted CPM', value: 'weightedCpm' },
                        { label: 'Weighted ROI (In-store)', value: 'weightedRoiInStore' },
                        { label: 'Weighted ROI (E-comm)', value: 'weightedRoiEcomm' },
                    ]}
                    value={sortBy}
                    onChange={(opt: Option<SortByOptions>) => {
                        setSortBy(opt.value)
                        setCurrentPage(1)
                    }}
                />
            </div>
            {isCampaignDataLoading && (
                <div style={{ height: `${TABLE_HEIGHT}px`, display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
                    <Loading />
                </div>
            )}
            {!isCampaignDataLoading && campaignData?.length === 0 && (
                <div style={{ height: `${TABLE_HEIGHT}px`, display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
                    <p>No data available</p>
                </div>
            )}
            {campaignData && campaignData.length > 0 && (
                <>
                    {/* Table */}
                    <div className="table-container" style={{ maxHeight: `${TABLE_HEIGHT}px` }}>
                        <table className={`table ${css['summary-table']}`}>
                            <thead>
                                <tr>
                                    <th>Campaign</th>
                                    <th>Cost</th>
                                    <th>No. of studies</th>
                                    <th>Evaluated Activities</th>
                                    <th>Total Impressions</th>
                                    <th>Weighted CPM</th>
                                    <th>Weighted ROI (In-store)</th>
                                    <th>Weighted ROI (E-comm)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {campaignData
                                    .sort((a, b) => {
                                        switch (sortBy) {
                                        case 'maxStudyCreatedDate':
                                            return simpleDate.isAfter(new Date(b.maxStudyCreatedDate), new Date(a.maxStudyCreatedDate)) ? 1 : -1;
                                        case 'campaignName':
                                            return a.campaign.name.localeCompare(b.campaign.name);
                                        case 'totalCost':
                                            return b.totalCost - a.totalCost;
                                        case 'numberOfStudies':
                                            return b.numberOfStudies - a.numberOfStudies;
                                        case 'evaluatedActivities':
                                            return b.evaluatedActivities - a.evaluatedActivities;
                                        case 'totalImpressions':
                                            return b.totalImpressions - a.totalImpressions;
                                        case 'weightedCpm':
                                            if (!a.weightedCpm.value) return 1;
                                            if (!b.weightedCpm.value) return -1;
                                            return b.weightedCpm.value - a.weightedCpm.value;
                                        case 'weightedRoiInStore':
                                            if (!a.weightedRoiInStore.value) return 1;
                                            if (!b.weightedRoiInStore.value) return -1;
                                            return b.weightedRoiInStore.value - a.weightedRoiInStore.value;
                                        case 'weightedRoiEcomm':
                                            if (!a.weightedRoiEcomm.value) return 1;
                                            if (!b.weightedRoiEcomm.value) return -1;
                                            return b.weightedRoiEcomm.value - a.weightedRoiEcomm.value;
                                        default:
                                            return 0;
                                        }
                                    })
                                    .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)  // Pagination
                                    .map((metrics) => (
                                        <tr key={metrics.campaign._id}>
                                            <td>
                                                <Link to={`/planner/campaigns/${metrics.campaign._id}/evaluations?openPlan=${metrics.plan._id}`}>{metrics.campaign.name}</Link>
                                            </td>
                                            <td>
                                                <Format.AsCurrency
                                                    value={metrics.totalCost}
                                                    decimals={2}
                                                />
                                            </td>
                                            <td>{metrics.numberOfStudies}</td>
                                            <td>
                                                {metrics.evaluatedActivities}
                                                {/* If any studies created in the last 14 days then show a new pill */}
                                                {simpleDate.isAfter(new Date(metrics.maxStudyCreatedDate), simpleDate.minusDays(simpleDate.today(), 14)) && (
                                                    <Pill className="ml-2" style={{ baselineSource: 'first'}} small colour="success">New</Pill>
                                                )}
                                            </td>
                                            <td>
                                                <Format.AsNumber
                                                    value={metrics.totalImpressions}
                                                />
                                            </td>
                                            <td>
                                                <Format.AsCurrency
                                                    value={metrics.weightedCpm.value}
                                                    decimals={2}
                                                />
                                            </td>
                                            <td>
                                                <Format.AsCurrency
                                                    value={metrics.weightedRoiInStore.value}
                                                    decimals={2}
                                                />
                                            </td>
                                            <td>
                                                <Format.AsCurrency
                                                    value={metrics.weightedRoiEcomm.value}
                                                    decimals={2}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        className="mt-3"
                        limit={PAGE_SIZE}
                        page={currentPage}
                        onChange={(page) => setCurrentPage(page)}
                        total={campaignData.length}
                    />
                </>
            )}
        </>
    )
}

export default CampaignBreakdownTable;