import css from './DashboardUpgradeBanner.module.scss';

interface DashboardUpgradeBannerProps {
    allRetailers: {
        value: string;
        label: string;
        backgroundColor?: string;
        textColor?: string;
        logoUrl?: string;
    }[];
    isProUser: boolean;
    retailerId?: string;
}

const DashboardUpgradeBanner = (props: DashboardUpgradeBannerProps) => {
    const { allRetailers, isProUser, retailerId } = props;

    const selectedRetailer = allRetailers?.find((retailer) => retailer?.value === retailerId);

    if (isProUser || !selectedRetailer) {
        return null;
    }

    const style = {};
    // @ts-expect-error
    if (selectedRetailer.backgroundColor) style.backgroundColor = selectedRetailer.backgroundColor;
    // @ts-expect-error
    if (selectedRetailer.textColor) style.color = selectedRetailer.textColor;

    return <div className={`${css['banner']}`} style={style}>
        {selectedRetailer.logoUrl && <img key={retailerId} src={selectedRetailer.logoUrl} alt={selectedRetailer.label} className={`${css['banner-content']} mr-3`} loading="lazy" />}
        <div className={css['banner-content']}>This is a {selectedRetailer.label}-only view of the Dashboard. Upgrade to Pro from a cross retailer view.</div>
    </div>
};

export default DashboardUpgradeBanner;
