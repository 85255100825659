import { memo } from 'react';

import './Icon.scss';

import { ReactComponent as Brain } from './svg/brain.svg';
import { ReactComponent as Cog } from './svg/cog.svg';
import { ReactComponent as Coins } from './svg/coins.svg';
import { ReactComponent as SleepingCat } from './svg/sleeping-cat.svg';
import { ReactComponent as ToggleRight } from './svg/toggle-right.svg';

// File icons
import { ReactComponent as FileArchive } from './svg/file-archive.svg';
import { ReactComponent as FileAudio } from './svg/file-audio.svg';
import { ReactComponent as FileExcel } from './svg/file-excel.svg';
import { ReactComponent as FileGeneric } from './svg/file-generic.svg';
import { ReactComponent as FilePowerpoint } from './svg/file-powerpoint.svg';
import { ReactComponent as FileVideo } from './svg/file-video.svg';
import { ReactComponent as FileWord } from './svg/file-word.svg';
import { ReactComponent as AddDates } from './svg/calendar-add.svg';

// Dice
import { ReactComponent as Dice1 } from './svg/dice-1.svg';
import { ReactComponent as Dice2 } from './svg/dice-2.svg';
import { ReactComponent as Dice3 } from './svg/dice-3.svg';
import { ReactComponent as Dice4 } from './svg/dice-4.svg';
import { ReactComponent as Dice5 } from './svg/dice-5.svg';
import { ReactComponent as Dice6 } from './svg/dice-6.svg';

// AI
import { ReactComponent as Ai } from './svg/ai.svg';

/**
 * Supports two types of icons in a uniform way
 * - Font icons from material design: https://fonts.google.com/icons?selected=Material+Icons
 * - SVG icons, which are read from the svg folder. For the technique used, see:
 *   https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
 *
 * To create new SVG icons, make sure that the following properties are important:
 * - width="1em"
 * - height="1em"
 * - fill="currentColor"
 */

// Map names to SVG components
const svgIconNames = {
    brain: Brain,
    cog: Cog,
    coins: Coins,
    toggleRight: ToggleRight,

    fileArchive: FileArchive,
    fileAudio: FileAudio,
    fileExcel: FileExcel,
    fileGeneric: FileGeneric,
    filePowerpoint: FilePowerpoint,
    fileVideo: FileVideo,
    fileWord: FileWord,

    dice1: Dice1,
    dice2: Dice2,
    dice3: Dice3,
    dice4: Dice4,
    dice5: Dice5,
    dice6: Dice6,

    sleepingCat: SleepingCat,

    ai: Ai,
    addDates: AddDates,
};

const Icon = ({ name, className='', size = '', ...otherProps}) => {
    const Svg = svgIconNames[name];
    const style = size ? {
        ...(otherProps.style || {}),
        fontSize: size,
        height: size,
        width: size,
    } : (otherProps.style || {});

    if (Svg) {
        style.userSelect = 'none';
        return <Svg {...otherProps} className={`svg-icons ${className}`} style={style} />
    } else {
        return (
            <i {...otherProps} className={`material-icons ${className}`} style={style}>{name}</i>
        );
    }
}

export default memo(Icon);
