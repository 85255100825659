import { useOutletContext } from 'react-router-dom';
import { Http, Icon, IndexPill, Loading, useFetch } from 'Components';
import { ViewDashboardPageContext } from 'Dashboard3/ViewDashboardPage';
import SummaryViewCard from './Components/SummaryViewCard';

import css from './SummaryView.module.scss';
import { formatters } from 'smg-common';

interface ValueAndCount {
    value?: number,
    count?: number
}

interface SummaryViewData {
    numberOfStudies: number,
    totalImpressions: number,
    weightedCpm: ValueAndCount,
    weightedRoiInStore: ValueAndCount,
    percentUplift: ValueAndCount,
    poundUpliftPerStore: ValueAndCount,
    poundUplift: ValueAndCount,
    valueOfMedia: number,
    evaluatedActivities: number,
    recentlyPublished: number,
}

const getCardTitle = (key: keyof SummaryViewData) => {
    switch (key) {
    case 'numberOfStudies':
        return 'No. of studies';
    case 'totalImpressions':
        return 'Total impressions';
    case 'weightedCpm':
        return 'Weighted CPM';
    case 'weightedRoiInStore':
        return 'Weighted ROI';
    case 'percentUplift':
        return '% uplift';
    case 'poundUpliftPerStore':
        return '£ uplift per store';
    case 'poundUplift':
        return '£ uplift';
    case 'valueOfMedia':
        return 'Value of media evaluated';
    default:
        return '';
    }
}

const getCardValue = (key: keyof SummaryViewData, value?: number) => {
    switch (key) {
    case 'weightedCpm':
    case 'weightedRoiInStore':
    case 'poundUpliftPerStore':
    case 'poundUplift':
    case 'valueOfMedia':
        return formatters.asCurrency(value, { decimals: 2 });
    case 'percentUplift':
        return formatters.asPercentage(value);
    default:
        return formatters.asNumber(value);
    }
}

const getCardFooter = (key: keyof SummaryViewData) => {
    switch (key) {
    case 'poundUplift':
    case 'poundUpliftPerStore':
    case 'weightedCpm':
    case 'weightedRoiInStore':
        return 'Live & post period';
    case 'percentUplift':
        return 'Live period';
    default:
        return undefined;
    }
}

const renderCards = (data: SummaryViewData) => {
    const cardOrder: (keyof SummaryViewData)[] = [
        'numberOfStudies',
        'percentUplift',
        'poundUpliftPerStore',
        'poundUplift',
        'valueOfMedia',
        'totalImpressions',
        'weightedCpm',
        'weightedRoiInStore',
    ]

    const nodes = cardOrder.map(key => {
        const value = data[key as keyof SummaryViewData];
        const numericValue = typeof value === 'object' ? value.value : value;

        return (
            <SummaryViewCard
                key={key}
                title={getCardTitle(key as keyof SummaryViewData)}
                value={getCardValue(key as keyof SummaryViewData, numericValue)}
                footer={getCardFooter(key as keyof SummaryViewData)}
                details={(() => {
                    switch (key) {
                    case 'numberOfStudies':
                        if (data['recentlyPublished'] === 0) return null;
                        return (
                            <div className={`${css['text-grey']} d-flex`}>
                                <Icon name="insights" className={css['recently-published-icon']} />
                                <span style={{ alignSelf: 'center' }}>{data['recentlyPublished']} published in the last 30 days</span>
                            </div>
                        )
                    case 'valueOfMedia':
                        return (
                            <div className={css['text-grey']}>
                                <span>{data['evaluatedActivities']} activities total</span>
                            </div>
                        )
                    case 'weightedCpm':
                    case 'weightedRoiInStore':
                    case 'percentUplift':
                    case 'poundUpliftPerStore':
                    case 'poundUplift':
                    case 'totalImpressions':
                        return (
                            <div className={css['text-grey']}>
                                <IndexPill className="d-inline" value={10} />
                                <span> vs Category</span>
                            </div>
                        )
                    default:
                        return null;
                    }
                })()}
            />
        )
    });

    return nodes;
}

const VIEW_HEIGHT = 350;

const SummaryView = () => {
    const { queryParams } = useOutletContext<ViewDashboardPageContext>();

    const controller = new AbortController();
    const [data, isDataLoading] = useFetch<SummaryViewData[]>(() => (
        Http.get('/dashboard3/in-store/summary-view', { params: queryParams, signal: controller.signal })
    ), [queryParams], { controller });

    return (
        <div>
            <h2 className="mb-3">Summary view</h2>
            {isDataLoading && (
                // Prevents CLS when the chart loads
                <div style={{ minHeight: `${VIEW_HEIGHT}px`, display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
                    <Loading />
                </div>
            )}
            {!isDataLoading && (!data || data.length === 0) && (
                <div style={{ minHeight: `${VIEW_HEIGHT}px`, display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>No data available</div>
            )}
            {data && data.length > 0 && !isDataLoading && (
                <div className={css['card-container']} style={{ minHeight: `${VIEW_HEIGHT}px` }}>
                    {renderCards(data[0])}
                </div>
            )}
        </div>
    )
}

export default SummaryView;