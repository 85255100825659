import { Icon } from '../index';
import css from './SortableTh.module.scss';

const SortableTh = (props) => {
    const { children, className, columnKey, onChange, orderBy } = props;

    const isSorted = orderBy.columnKey === columnKey;
    const isDesc = orderBy.direction === 'desc';

    return (
        <th className={`${css.clickable} ${className}`} onClick={() => onChange({ columnKey, direction: isSorted && isDesc ? 'asc' : 'desc' })}>
            {children}
            { isSorted && <Icon className="ml-2" name={isDesc ? 'expand_more' : 'expand_less'} /> }
        </th>
    );
};

export default SortableTh;
