import { PropsWithChildren } from 'react';
import { PopoverTriggerProps } from '@radix-ui/react-popover';

import { Icon } from 'Components';
import { Popover, PopoverContent, PopoverTrigger} from './Popover';

import buttonCss from 'Components/Button/Button.module.scss';
import iconButtonCss from 'Components/Button/IconButton.module.scss';
import css from './ToolbarFilterPopover.module.scss';

interface ToolbarFilterPopoverProps {
    triggerProps?: PopoverTriggerProps,
    iconName?: string,
}

/** An abstraction over the popover to be used for rendering toolbar filter menus  */
const ToolbarFilterPopover = (props: PropsWithChildren<ToolbarFilterPopoverProps>) => {
    const { children, triggerProps, iconName } = props;

    return (
        <Popover>
            {/* MP 2023/10/04
                Ideally this would be `PopoverTrigger` with the `asChild` prop, and an `IconButton` (or similar)
                component as it's child. Effectively `PopoverTrigger` here is acting as a button, which is duplicating
                functionality of a reusable `Button` component.
                Unfortunately to do this the child component would need `forwardRef`, and due to the underlying `Button`
                component being polymorphic, it's complicated to do this.
                We should deprecate the `Button` component (split into different types e.g. LinkButton, DropdownButton)
                and then pass a new button component as a child here.
            */}
            <PopoverTrigger
                className={`${iconButtonCss['icon-button']} ${buttonCss['btn']} ${buttonCss['default']} ${triggerProps?.disabled ? buttonCss['disabled'] : ''} stack-center-center-2 ml-2`}
                {...triggerProps}
            >
                <Icon name={iconName ?? 'tune'} />
            </PopoverTrigger>
            <PopoverContent align="start" sideOffset={1} className={css['content']}>
                {children}
            </PopoverContent>
        </Popover>
    );
};

export default ToolbarFilterPopover;
