import { ChangeEvent, useState } from 'react';
import { Option } from 'smg-common';
import { ReactSelect, useFetch, Http, Loading, ButtonGroup, Button, Icon } from 'Components';
import { useOutletContext } from 'react-router-dom';
import { ViewDashboardPageContext } from 'Dashboard3/ViewDashboardPage';
import BreakdownChart from './BreakdownChart';
import BreakdownTable from './BreakdownTable';

type BreakdownByOptions = 'brand' | 'category' | 'year' | 'yearMonth'
type MetricOptions = 'weightedRoiInStore' | 'weightedRoiEcomm' | 'evaluatedActivities' | 'weightedCpm'

interface BreakdownMetrics {
    label: string;
    evaluatedActivities: number;
    weightedCpm: {
        value?: number;
        count?: number;
    };
    weightedRoiInStore: {
        value?: number;
        count?: number;
    };
    weightedRoiInStoreFsku: {
        value?: number;
        count?: number;
    };
    weightedRoiEcomm: {
        value?: number;
        count?: number;
    };
}

const RESULTS_OPTIONS = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: 'All', value: Infinity },
] as const;

const BREAKDOWN_OPTIONS = [
    { label: 'Brand', value: 'brand' },
    { label: 'Category', value: 'category' },
    { label: 'Year', value: 'year' },
    { label: 'Year & month', value: 'yearMonth' },
] as const;

const METRIC_OPTIONS = [
    { label: 'Weighted CPM', value: 'weightedCpm' },
    { label: 'Weighted ROI (In-store)', value: 'weightedRoiInStore' },
    { label: 'Weighted ROI (E-comm)', value: 'weightedRoiEcomm' },
    { label: 'Evaluated activities', value: 'evaluatedActivities' },
] as const;

const CHART_HEIGHT = 600;

const BreakdownView = () => {
    const { queryParams } = useOutletContext() as ViewDashboardPageContext;

    const [numberOfResults, setNumberOfResults] = useState<number>(10)
    const [breakdownBy, setBreakdownBy] = useState<BreakdownByOptions>('brand')
    const [metric, setMetric] = useState<MetricOptions>('weightedCpm')
    const [activeView, setActiveView] = useState<'chart' | 'table'>('chart')

    const controller = new AbortController();
    const [data, isDataLoading] = useFetch<BreakdownMetrics[]>(() => (
        Http.get('/dashboard3/breakdown-view', {
            params: {
                ...queryParams,
                groupBy: breakdownBy,
            },
            signal: controller.signal
        })
    ), [queryParams, breakdownBy], { controller });

    const getBreakdownLabel = (level: 'chart' | 'section') => {
        const option = BREAKDOWN_OPTIONS.find(opt => opt.value === breakdownBy);
        if (!option) return '';
        return level === 'chart' ? option.label : `${option.label} level`;
    }

    const getMetricLabel = () => {
        const option = METRIC_OPTIONS.find(opt => opt.value === metric);
        if (!option) return '';
        return option.label;
    }

    const sortedData = data?.sort((a, b) => {
        switch (metric) {
        case 'weightedRoiInStore':
            return (b.weightedRoiInStore.value || 0) - (a.weightedRoiInStore.value || 0)
        case 'weightedRoiEcomm':
            return (b.weightedRoiEcomm.value || 0) - (a.weightedRoiEcomm.value || 0)
        case 'weightedCpm':
            return (b.weightedCpm.value || 0) - (a.weightedCpm.value || 0)
        case 'evaluatedActivities':
            return b.evaluatedActivities - a.evaluatedActivities
        default:
            return 0;
        }
    }).slice(0, numberOfResults) || [];

    return (
        <div className="stack-col">
            <>
                <h3 className="mb-3">{getBreakdownLabel('section')} breakdown</h3>
                <div className="stack-end-end" style={{ gap: '1rem' }}>
                    <ButtonGroup
                        activeColor="primary"
                        value={activeView}
                        onChange={(_evt: ChangeEvent, value: 'chart' | 'table') => setActiveView(value)}
                        style={{ marginBottom: '0.5rem' }}
                    >
                        <Button value="chart" style={{ padding: 0 }}>
                            <Icon name="bar_chart" />
                        </Button>
                        <Button value="table" style={{ padding: 0 }}>
                            <Icon name="table_chart" />
                        </Button>
                    </ButtonGroup>
                    <ReactSelect
                        label="Number of results"
                        style={{ minWidth: '200px' }}
                        options={RESULTS_OPTIONS}
                        value={numberOfResults}
                        onChange={(opt: Option<number>) => setNumberOfResults(opt.value)}
                    />
                    <ReactSelect
                        label="Breakdown by"
                        style={{ minWidth: '200px' }}
                        options={BREAKDOWN_OPTIONS}
                        value={breakdownBy}
                        onChange={(opt: Option<BreakdownByOptions>) => setBreakdownBy(opt.value)}
                    />
                    <ReactSelect
                        label="Metric"
                        style={{ minWidth: '200px' }}
                        options={METRIC_OPTIONS}
                        value={metric}
                        onChange={(opt: Option<MetricOptions>) => setMetric(opt.value)}
                    />
                </div>
            </>
            {isDataLoading && (
                // Prevents CLS when the chart loads
                <div style={{ height: `${CHART_HEIGHT}px`, display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
                    <Loading />
                </div>
            )}
            {!isDataLoading && activeView === 'chart' && (
                <BreakdownChart
                    data={sortedData}
                    metricLabel={getMetricLabel()}
                    height={CHART_HEIGHT}
                    selectedMetric={metric}
                />
            )}
            {!isDataLoading && activeView === 'table' && (
                <BreakdownTable
                    data={sortedData}
                    selectedMetric={metric}
                    height={CHART_HEIGHT}
                    metricLabel={getMetricLabel()}
                    breakdownLabel={getBreakdownLabel('chart')}
                />
            )}
        </div>
    )
}

export {
    type BreakdownMetrics,
    type MetricOptions,
}

export default BreakdownView;