import { formatters } from 'smg-common';
import { MetricOptions } from './BreakdownView';

const formatValue = (value: number, metric: MetricOptions) => {
    switch (metric) {
    case 'weightedRoiInStore':
    case 'weightedRoiEcomm':
    case 'weightedCpm':
        return formatters.asCurrency(value, { decimals: 2 });
    case 'evaluatedActivities':
        return formatters.humanise(value);
    }
};

export {
    formatValue,
}