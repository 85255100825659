import { DatePickerField, ReactSelect, ToolbarFilterPopover } from 'Components'
import { Option, simpleDate } from 'smg-common';

import type { FilterOptions, FilterValues } from './Types/DashboardFilters';

interface DashboardFiltersProps {
    filterOptions: FilterOptions;
    isProUser: boolean;
    onFilterChange: (filter: string, value: any) => void;
    selectedFilters: FilterValues;
}

const renderLast12MonthsButton = (fieldName: string, fieldValue: string, onFilterChange: (filter: string, value: string) => void) => {
    const now = new Date();
    const oneYearAgo = simpleDate.minusMonths(now, 12);
    const oneYearAgoStr = simpleDate.format(oneYearAgo, 'YYYY-MM-DD');

    return oneYearAgoStr !== fieldValue
        ? <span className="like-link" onClick={() => onFilterChange(fieldName, oneYearAgoStr)}>Last 12 months</span>
        : <></>;
};

const renderTodayButton = (fieldName: string, fieldValue: string, onFilterChange: (filter: string, value: string) => void) => {
    const now = simpleDate.format(new Date(), 'YYYY-MM-DD');

    return now !== fieldValue
        ? <span className="like-link" onClick={() => onFilterChange(fieldName, now)}>Today</span>
        : <></>;
};

const DashboardFilters = (props: DashboardFiltersProps) => {
    const { filterOptions, isProUser, onFilterChange, selectedFilters } = props;

    return <div className="stack-end-end">
        {filterOptions.retailers?.length > 1 && <ReactSelect
            name="retailers"
            label={isProUser ? 'Retailers' : 'Retailer'}
            className="mr-3"
            style={{ minWidth: '14em' }}
            theme="light"
            options={filterOptions.retailers}
            value={selectedFilters.retailers}
            // @ts-expect-error
            onChange={(value: Option[] | Option) => onFilterChange('retailers', isProUser ? value?.map(r => r.value) : value.value)}
            isMulti={isProUser}
            isClearable={false}
        />}
        <DatePickerField
            label="From"
            name="dateFrom"
            className="mr-3"
            style={{ width: '14em' }}
            theme="light"
            value={selectedFilters?.dateFrom ? simpleDate.parse(selectedFilters.dateFrom, 'YYYY-MM-DD') : undefined}
            onChange={(value: Date) => onFilterChange('dateFrom', simpleDate.format(value, 'YYYY-MM-DD') )}
            topRightSlot={renderLast12MonthsButton('dateFrom', selectedFilters.dateFrom, onFilterChange)}
        />
        <DatePickerField
            label="To"
            name="dateTo"
            className="mr-3"
            style={{ width: '14em' }}
            theme="light"
            value={selectedFilters?.dateTo ? simpleDate.parse(selectedFilters.dateTo, 'YYYY-MM-DD') : undefined}
            onChange={(value: Date) => onFilterChange('dateTo', simpleDate.format(value, 'YYYY-MM-DD') )}
            topRightSlot={renderTodayButton('dateTo', selectedFilters.dateTo, onFilterChange)}
        />
        {filterOptions.companies?.length > 1 && <ReactSelect
            name="company"
            label="Company"
            className="mr-3"
            style={{ width: '14em' }}
            theme="light"
            options={filterOptions.companies}
            value={selectedFilters.company}
            onChange={(value: Option) => onFilterChange('company', value.value )}
        />}
        <div className="pb-2">
            <ToolbarFilterPopover>
                <ReactSelect
                    name="brands"
                    label="Brands"
                    options={filterOptions.brands?.filter((groupedBrandOptions) => {
                        return selectedFilters.company === groupedBrandOptions._id
                    })}
                    value={selectedFilters.brands}
                    onChange={(value: Option[]) => onFilterChange('brands', value.map(b => b.value) )}
                    isMulti
                />
                <ReactSelect
                    name="xCategories"
                    label="Categories"
                    options={filterOptions.xCategories}
                    value={selectedFilters.xCategories}
                    onChange={(value: Option[]) => onFilterChange('xCategories', value?.map(c => c.value) )}
                    isMulti
                />
                <ReactSelect
                    name="isNpd"
                    label="Is NPD"
                    options={filterOptions.isNpd}
                    value={selectedFilters.isNpd}
                    onChange={(value: Option[]) => onFilterChange('isNpd', value?.map(o => o.value) )}
                    isMulti
                />
                <ReactSelect
                    name="isRetailerEvent"
                    label="Is retailer event"
                    options={filterOptions.isRetailerEvent}
                    value={selectedFilters.isRetailerEvent}
                    onChange={(value: Option[]) => onFilterChange('isRetailerEvent', value?.map(o => o.value) )}
                    isMulti
                />
                <ReactSelect
                    name="touchpointCreative"
                    label="Touchpoint creative"
                    options={filterOptions.touchpointCreative}
                    value={selectedFilters.touchpointCreative}
                    onChange={(value: Option[]) => onFilterChange('touchpointCreative', value?.map(o => o.value) )}
                    isMulti
                />
                <ReactSelect
                    name="competitionPrize"
                    label="Competition"
                    options={filterOptions.competitionPrize}
                    value={selectedFilters.competitionPrize}
                    onChange={(value: Option[]) => onFilterChange('competitionPrize', value?.map(o => o.value) )}
                    isMulti
                />
            </ToolbarFilterPopover>
        </div>
    </div>;
}

export default DashboardFilters
