import { useCallback, useEffect, useState } from 'react';

type Return<T> = [
    T | undefined,
    boolean,
    string | undefined,
    Execute,
];

interface LoadState<T> {
    isLoading: boolean;
    fetchedData: T | undefined;
    errorMessage: string | undefined;
}

interface ExecuteOptions {
    hideLoading: boolean;
}

type Execute = (options?: ExecuteOptions) => void;

interface FetchOptions {
    controller?: AbortController;
}

/**
 * A React hook that can fetch data from the API (or resolve any other promise)
 * @param {function} loadFx a function that returns a Promise
 * @param {*} syncData an array that determines when the loadFx should be run. It is identical in to useEffect()
 */
function useFetch<T = unknown>(loadFx: () => Promise<T | undefined>, syncData: unknown[], fetchOptions?: FetchOptions): Return<T> {
    const [loadState, setLoadState] = useState<LoadState<T>>({
        isLoading: true,
        fetchedData: undefined,
        errorMessage: undefined,
    });

    // Memoize the async function that fetches the data, so that it only runs when syncData changes
    const safeLoadFx = useCallback(loadFx, syncData); // eslint-disable-line react-hooks/exhaustive-deps

    const execute: Execute = (
        options = {
            hideLoading: false,
        },
    ) => {
        setLoadState({
            isLoading: !options.hideLoading,
            fetchedData: undefined,
            errorMessage: undefined,
        });

        safeLoadFx()
            .then((fetchedData) => {
                setLoadState({
                    isLoading: false,
                    fetchedData,
                    errorMessage: undefined,
                });
            })
            .catch((err) => {
                if (fetchOptions?.controller?.signal.aborted) {
                    setLoadState({
                        isLoading: false,
                        fetchedData: undefined,
                        errorMessage: undefined,
                    });
                } else {
                    console.error('useFetch promise failed', err);
                    setLoadState({
                        isLoading: false,
                        fetchedData: undefined,
                        errorMessage: err.message || 'Network error. Failed to load.',
                    });
                }
            });
    }

    useEffect(() => {
        execute();
        return () => {
            if (fetchOptions?.controller) {
                fetchOptions.controller.abort();
            }
        }
    }, [safeLoadFx]); // eslint-disable-line react-hooks/exhaustive-deps

    return [loadState.fetchedData, loadState.isLoading, loadState.errorMessage, execute];
}

export default useFetch;
