import { isFunction } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { MouseEvent, ReactNode } from 'react';

import css from './Button.module.scss';
import { Dropdown, Icon } from '..';

interface Option {
    className?: string;
    label?: string;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    to?: string;
    type?: 'option' | 'divider';
}

type LinkProps = {
    pathname: string;
    search: string;
}
interface ButtonProps {
    active?: boolean;
    children: ReactNode
    className?: string;
    color?: 'default' | 'inverse' | 'primary' | 'secondary' | 'success' | 'danger' | 'mud';
    disabled?: boolean;
    href?: string;
    linkTo?: string| LinkProps;
    onClick?: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
    options?: Option[];
    size?: string;
    textAlign?: string;
    type?: 'button' | 'submit' | 'reset';
    [propName: string]: unknown;
}

const Button = (props: ButtonProps) => {
    const {
        active,
        children,
        className = '',
        color = 'default',
        disabled,
        href,
        linkTo,
        onClick,
        options = [],
        size,
        textAlign = 'center',
        type = 'button',
        ...restProps
    } = props;

    const navigate = useNavigate();

    const hasOptions = options.length > 0;
    const textAlignClass = textAlign === 'center' ? 'stack-center-center-2' : 'stack-start-center-2';
    const classes = [css.btn, css[color], textAlignClass, className];
    if (disabled) classes.push(css.disabled);
    if (hasOptions) classes.push(css['has-options']);
    if (size === 'small') classes.push(css.small);
    if (size === 'large') classes.push(css.large);

    const triggerClasses = [css.btn, css.trigger, css[color], 'stack-center-center-2', className];

    const handleOnClick = (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        // const eventTarget = event.currentTarget as HTMLAnchorElement | HTMLButtonElement;

        // We want to blur immediately after clicking, to remove the :focus styling
        event.currentTarget.blur();

        if (isFunction(onClick)) {
            onClick(event);
        }
    };

    if (hasOptions) {
        const trigger = (<button className={triggerClasses.join(' ').trim()} type={type}>
            <Icon name="arrow_drop_down"/>
        </button>);

        const handleOptionClick = async (event: MouseEvent<HTMLDivElement>, option: Option = {}) => {
            const eventTarget = event.currentTarget as HTMLDivElement;

            eventTarget.blur();

            if (option.to) {
                navigate(option.to);
            } else {
                if (isFunction(option.onClick)) {
                    option.onClick(event)
                }
            }
        };

        return (<div className={css['button-dropdown']}>
            <Button
                className={classes.join(' ')}
                color={color}
                disabled={disabled}
                onClick={handleOnClick}
                linkTo={linkTo}
                size={size}
                type={type}
                {...restProps}
            >
                {children}
            </Button>
            {/* DropdownMenu would have been preferred here, but Dropdown is used due to custom list implementation */}
            <Dropdown trigger={trigger}>
                <div className="box-raised">
                    {options.map((option, index) => {
                        if (!option.type || (option.type === 'option')) {
                            return (<div
                                className={[css.option, option.className].join(' ').trim()}
                                key={index}
                                onClick={(event) => handleOptionClick(event, option)}
                            >
                                {option.label}
                            </div>);
                        } else if (option.type === 'divider') {
                            return <hr key={index} className={[css.divider, option.className].join(' ').trim()} />;
                        }
                        return null;
                    })}
                </div>
            </Dropdown>
        </div>);
    } else if (linkTo) {
        return (<Link to={linkTo} className={classes.join(' ')} onClick={handleOnClick} {...restProps}>{children}</Link>);
    } else if (href) {
        return (<a href={href} className={classes.join(' ')} onClick={handleOnClick} {...restProps}>{children}</a>);
    } else {
        return (<button className={classes.join(' ')} onClick={handleOnClick} type={type} {...restProps} >{children}</button>);
    }
}

export default Button;
