import { formatters } from 'smg-common';
import { useLocale } from 'Components';

interface AsSimpleDateProps {
    format?: 'short' | 'long' | 'iso';
    na?: string;
    value: Date | string | null | undefined;
}

const AsSimpleDate = (props: AsSimpleDateProps) => {
    // GOTCHA: if you pass `null` in any of the options, it will override the default
    // value, whereas `undefined` will result in the default value being used

    const { defaultLocale } = useLocale();

    const {
        format, // 'short' (31/12/2021) OR 'long' (31 December 2021) OR 'iso' (2021-12-31)
        na,
        value,
    } = props;

    return formatters.asSimpleDate(value, defaultLocale, { format, na });
};

export default AsSimpleDate;
