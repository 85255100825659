const acceptedImageTypeOptions = [
    { label: 'JPG', value: 'jpg' },
    { label: 'JPEG', value: 'jpeg' },
    { label: 'GIF', value: 'gif' },
    { label: 'PNG', value: 'png' },
    { label: 'PSD', value: 'psd' },
    { label: 'PDF', value: 'pdf' },
    { label: 'SVG', value: 'svg' },
    { label: 'INDD', value: 'indd' },
    { label: 'AI', value: 'ai' },
    { label: 'EPS', value: 'eps' },
    { label: 'TIFF', value: 'tiff' },
    { label: 'MP4', value: 'mp4' },
    { label: 'ZIP', value: 'zip' },
] as const;

const workflowTypes = ['activityBrief', 'initialCreative', 'finalCreative'];

const defaultApprovalGroups = ['Legal', 'Client', 'Brand'];

export { acceptedImageTypeOptions, workflowTypes, defaultApprovalGroups };
