import { useOutletContext } from 'react-router-dom';

import { ViewDashboardPageContext } from 'Dashboard3/ViewDashboardPage';
import { Format, Http, IndexPill, Legend, Loading, useFetch } from 'Components';

import css from './SummaryView.module.scss';

interface Row {
    label: string;
    numberOfStudies: number;
    evaluatedActivities: number;
    totalImpressions: number;
    weightedCpm: { value: number | null | undefined; count: number };
    weightedCpmBenchmarkIndex: number;
    weightedRoiInStore: { value: number | null | undefined; count: number };
    weightedRoiInStoreBenchmarkIndex: number;
    weightedRoiEcomm: { value: number | null | undefined; count: number };
    weightedRoiEcommBenchmarkIndex: number;
}

const SummaryView = () => {
    const { queryParams } = useOutletContext<ViewDashboardPageContext>();

    const controller = new AbortController();
    const [data, isDataLoading] = useFetch<Row[]>(() => (
        Http.get('/dashboard3/summary-view', { params: queryParams, signal: controller.signal })
    ), [queryParams], { controller });

    return (
        <>
            <h2 className="mb-3">Summary view</h2>
            {isDataLoading && (
                // Prevents CLS when the chart loads
                <div style={{ height: '500px', display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
                    <Loading />
                </div>
            )}
            {!isDataLoading && (!data || data.length === 0) && (
                <div style={{ height: '500px', display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>No data available</div>
            )}
            {!isDataLoading && data && data.length > 0 && (
                <div className="table-container" style={{ maxHeight: '500px' }}>
                    <table className={css['summary-table']}>
                        <thead>
                            <tr>
                                <th>{/* Row label */}</th>
                                <th className="text-right">No. of studies</th>
                                <th className="text-right">Evaluated activities</th>
                                <th className="text-right">Total impressions</th>
                                <th className="text-right">Weighted CPM</th>
                                <th className="text-right">Weighted ROI (In-store) *</th>
                                <th className="text-right">Weighted ROI (E-comm) *</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length === 0 ? (
                                <tr>
                                    <td colSpan={7} className="text-center">
                                            No data available
                                    </td>
                                </tr>
                            ) : (
                                data.map((row, i) => (
                                    <tr key={i}>
                                        {/* The first row is the company total so needs to be styled differently */}
                                        <td className={i === 0 ? 'text-bold' : ''}>{i === 0 && 'Total '}{row.label}</td>
                                        <td className={`text-right ${i === 0 ? 'text-bold' : ''}`}>
                                            <Format.AsNumber value={row.numberOfStudies} />
                                        </td>
                                        <td className={`text-right ${i === 0 ? 'text-bold' : ''}`}>
                                            <Format.AsNumber value={row.evaluatedActivities} />
                                        </td>
                                        <td className={`text-right ${i === 0 ? 'text-bold' : ''}`}>
                                            <Format.AsNumber value={row.totalImpressions} na="-" />
                                        </td>
                                        <td className={`text-right ${i === 0 ? 'text-bold' : ''}`}>
                                            <Format.AsCurrency decimals={2} value={row.weightedCpm.value} />
                                            {row.weightedCpmBenchmarkIndex && (
                                                <IndexPill className="d-inline ml-2" value={row.weightedCpmBenchmarkIndex} />
                                            )}
                                        </td>
                                        <td className={`text-right ${i === 0 ? 'text-bold' : ''}`}>
                                            <Format.AsCurrency decimals={2} value={row.weightedRoiInStore.value} />
                                            {row.weightedRoiInStoreBenchmarkIndex && (
                                                <IndexPill className="d-inline ml-2" value={row.weightedRoiInStoreBenchmarkIndex} />
                                            )}
                                        </td>
                                        <td className={`text-right ${i === 0 ? 'text-bold' : ''}`}>
                                            <Format.AsCurrency decimals={2} value={row.weightedRoiEcomm.value} />
                                            {row.weightedRoiEcommBenchmarkIndex && (
                                                <IndexPill className="d-inline ml-2" value={row.weightedRoiEcommBenchmarkIndex} />
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <div className="text-right text-subtle mt-2">
                        <span>* Values based on live & post period</span>
                    </div>
                    <div aria-labelledby="benchmarks-legend" className="d-flex align-items-center justify-content-center mt-3" role="group">
                        <p className="m-0 text-bold" id="benchmarks-legend">Benchmarks:</p>
                        <Legend className="ml-3" label="Above" color="success" role="presentation" />
                        <Legend className="ml-3" label="Within 10%" color="primary" role="presentation" />
                        <Legend className="ml-3" label="Below" color="danger" role="presentation" />
                    </div>
                </div>
            )}
        </>
    );
};

export default SummaryView;
