import BreakdownView from './BreakdownView';
import CampaignBreakdownTable from './CampaignBreakdownTable';

import SummaryView from './SummaryView';

const DashboardOverviewPage = () => {
    return (
        <>
            <div className="box-raised p-4 mb-3">
                <SummaryView />
            </div>
            <div className="box-raised p-4 mb-3">
                <BreakdownView />
            </div>
            <div className="box-raised p-4">
                <CampaignBreakdownTable />
            </div>
        </>
    )
}

export default DashboardOverviewPage;