/* eslint-disable no-param-reassign */
import { get } from 'lodash-es';
import moment from 'moment';
import simpleDate from './simpleDate.js';
import { calculateActivityDiscountsValue } from './index.js';

// Returns actual impressions for engagement or digital tps - otherwise returns est impressions
const getActivityImpressions = (activity) => {
    if (!activity) {
        return undefined;
    }

    const impressionsGroup = activity.touchpoint?.impressionsGroup;
    return (impressionsGroup === 'engagement' || impressionsGroup === 'digital')
        ? activity.actualImpressions
        : activity.impressions;
};

function calculateImpressionsForStoreFootfall(activity, touchpoint, discountForMediaLocation, compliance) {
    // Fetch all of the required values for the calculation
    const numberOfStores = +get(activity, 'numberOfStores', null);
    const otsPerTrip = +get(touchpoint, 'impressions.otsPerTrip', null);
    const storesetFootfallPerWeekPerStore = +get(activity, 'storeSet.footfall', null);
    const shareOfVoice = +activity.discountShareOfVoice || +get(touchpoint, 'impressions.discountShareOfVoice', null);

    let numberOfWeeks;
    if (activity.duration) {
        numberOfWeeks = activity.duration / 7;
    } else if (activity.startDate && activity.endDate) {
        // Add one day because both start and end date are included
        numberOfWeeks = (moment(activity.endDate).diff(activity.startDate, 'days') + 1) / 7;
    } else if (touchpoint.duration) {
        numberOfWeeks = touchpoint.duration / 7;
    } else {
        numberOfWeeks = undefined;
    }

    const hasAllNumbers = storesetFootfallPerWeekPerStore
        && numberOfStores
        && numberOfWeeks
        && shareOfVoice
        && otsPerTrip
        && discountForMediaLocation
        && compliance;

    if (hasAllNumbers) {
        // Calculate the impressions (include aisle penetration if SJ is 'at the fixture')
        const shopperJourney = get(touchpoint, 'shopperJourney.slug') || get(touchpoint, 'shopperJourneySlug');
        if (shopperJourney !== 'at-the-fixture') {
            return (storesetFootfallPerWeekPerStore * numberOfStores * numberOfWeeks)
                * (discountForMediaLocation / 100)
                * (compliance / 100)
                * (shareOfVoice / 100)
                * otsPerTrip;
        }
        const aislePenetration = +get(activity, 'aislePenetration', null);
        if (aislePenetration) {
            return (storesetFootfallPerWeekPerStore * numberOfStores * numberOfWeeks)
                * (discountForMediaLocation / 100)
                * (compliance / 100)
                * (shareOfVoice / 100)
                * otsPerTrip
                * (aislePenetration / 100);
        }
    }

    return undefined;
}

function calculateImpressionsForIssuance(activity, touchpoint, discountForMediaLocation, compliance) {
    const numberOfIssues = +get(activity, 'issues', null);
    const otsPerCount = +get(touchpoint, 'impressions.otsPerCount', null);
    if (numberOfIssues && otsPerCount && discountForMediaLocation && compliance) {
        return numberOfIssues * (discountForMediaLocation / 100) * (compliance / 100) * otsPerCount;
    }
    return undefined;
}

function calculateImpressionsForPressAndPrint(touchpoint, discountForMediaLocation, compliance) {
    const readership = +get(touchpoint, 'impressions.readership', null);
    const otsPerCount = +get(touchpoint, 'impressions.otsPerCount', null);
    if (readership && otsPerCount && discountForMediaLocation && compliance) {
        return readership * (discountForMediaLocation / 100) * (compliance / 100) * otsPerCount;
    }
    return undefined;
}

function calculateActivityImpressions(activity, options = {}) {
    // Do not calculate impressions if activity is live/historic/cancelled
    if (!options.skipDateCheck && simpleDate.isBefore(activity.startDate, new Date())) {
        return activity.impressions;
    }

    const { touchpoint } = activity;

    const discountForMediaLocation = +get(touchpoint, 'impressions.discountForMediaLocation', null);
    const compliance = +get(touchpoint, 'impressions.discountAvgCompliance', null);
    const impressionsGroup = get(touchpoint, 'impressionsGroup');

    // The formula to calculate impressions changes depending on the impressions group (and store-set if IP is store-footfall)
    switch (impressionsGroup) {
        case 'store-footfall':
            activity.impressions = calculateImpressionsForStoreFootfall(activity, touchpoint, discountForMediaLocation, compliance);
            break;
        case 'issuance':
            activity.impressions = calculateImpressionsForIssuance(activity, touchpoint, discountForMediaLocation, compliance);
            break;
        case 'press-and-print':
            activity.impressions = calculateImpressionsForPressAndPrint(touchpoint, discountForMediaLocation, compliance);
            break;
        case 'digital':
            activity.impressions = +activity.impressions;
            break;
        case 'impressions-only':
            activity.impressions = +activity.impressions;
            break;
        case 'engagement':
            activity.impressions = +activity.impressions;
            break;
        default:
            activity.impressions = undefined;
            break;
    }

    return activity.impressions ? Math.ceil(activity.impressions) : undefined;
}

function calculateCpm(activity, options = {}) {
    // Do not calculate CPM if activity is live/historic/cancelled
    if (!options.skipDateCheck && simpleDate.isBefore(activity.startDate, new Date())) {
        return activity.cpm;
    }

    const impressionsGroup = get(activity, 'touchpoint.impressionsGroup');

    // CPM for digital touchpoints are manually inputted
    if (impressionsGroup !== 'digital' && impressionsGroup !== 'engagement') {
        const cost = +activity.cost;
        const impressions = +activity.impressions;
        const impressionsPerMillia = impressions > 0 ? (impressions / 1000) : undefined;
        return cost && impressionsPerMillia ? parseFloat((cost / impressionsPerMillia).toFixed(2)) : undefined;
    } else {
        return activity.cpm;
    }
}

function calculateOverallActivityImpressions(overallActivity, activities) {
    // If all activities have no impressions (isNaN), remove the overall activity impressions
    const hasNumericImpressions = activities.find((activity) => !Number.isNaN(getActivityImpressions(activity)));

    const impressionsPerStore = hasNumericImpressions
        ? activities.reduce((total, activity) => {
            return (+getActivityImpressions(activity) / +activity.numberOfStores) + total;
        }, 0)
        : null;

    return impressionsPerStore && overallActivity?.numberOfStores
        ? impressionsPerStore * +overallActivity.numberOfStores
        : undefined;
}

function calculateOverallCpm(overallActivity, activities) {
    const costPerStore = activities.reduce((total, activity) => {
        return (+activity.cost / +activity.numberOfStores) + total;
    }, 0);
    const costOverlap = costPerStore * +overallActivity.numberOfStores;

    const impressionsPerStore = activities.reduce((total, activity) => {
        return (+getActivityImpressions(activity) / +activity.numberOfStores) + total;
    }, 0);
    const impressionsPerMillia = (impressionsPerStore * +overallActivity.numberOfStores) / 1000;

    return costOverlap && impressionsPerMillia
        ? parseFloat((costOverlap / impressionsPerMillia).toFixed(2))
        : undefined;
}

// For pre-vs-live & regression activities - overall activity is calculated as the sum of all activities
function calculateOverallActivityAsSum(activities) {
    const touchpoints = [];
    let totalMediaValue;
    let totalActualCost; // Cost after discounts have been applied
    let totalImpressions;
    let earliestStartDate;
    let latestEndDate;

    activities?.forEach(activity => {
        touchpoints.push(activity.touchpoint._id);
        const impressions = getActivityImpressions(activity);
        totalMediaValue = isFinite(activity.cost) ? (totalMediaValue || 0) + activity.cost : totalMediaValue;
        totalImpressions = isFinite(impressions) ? (totalImpressions || 0) + impressions : totalImpressions;

        const discountValue = calculateActivityDiscountsValue(activity);
        const actualCost = activity.cost - discountValue;
        totalActualCost = isFinite(actualCost) ? (totalActualCost || 0) + actualCost : totalActualCost;

        if (!earliestStartDate || simpleDate.isBefore(activity.startDate, earliestStartDate)) {
            earliestStartDate = activity.startDate;
        }

        if (!latestEndDate || simpleDate.isAfter(activity.endDate, latestEndDate)) {
            latestEndDate = activity.endDate;
        }
    });

    const totalCpm = totalMediaValue && totalImpressions ? (totalMediaValue / totalImpressions * 1000) : undefined;

    return {
        impressions: totalImpressions,
        cpm: totalCpm,
        cost: totalMediaValue,
        actualCost: totalActualCost,
        startDate: earliestStartDate,
        endDate: latestEndDate,
        touchpoints,
    };
}

export default {
    calculateActivityImpressions,
    calculateCpm,
    calculateOverallActivityAsSum,
    calculateOverallActivityImpressions,
    calculateOverallCpm,
    getActivityImpressions,
};
