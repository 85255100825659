import { formatters } from 'smg-common';
import { useLocale } from 'Components';

interface AsTimestampProps {
    format?: 'medium' | 'fromNow' | 'iso';
    na?: string;
    value: Date | string | null | undefined;
}

const AsTimestamp = (props: AsTimestampProps) => {
    // GOTCHA: if you pass `null` in any of the options, it will override the default
    // value, whereas `undefined` will result in the default value being used

    const { defaultLocale } = useLocale();

    const {
        format,
        na,
        value,
    } = props;

    return formatters.asTimestamp(value, defaultLocale, { format, na });
}

export default AsTimestamp;
