import { Activity, Workflow } from 'Types';
import { Format } from 'Components';
import { getDateColor, getDeadlineDate, getWorkflowCreativeType } from '../utils';

interface RenderDeadlineProps {
    activity: Pick<
        Activity,
        | 'briefingDeadline'
        | 'briefingApprovalDeadline'
        | 'initialCreativeDeadline'
        | 'initialCreativeApprovalDeadline'
        | 'artworkDeadline'
        | 'artworkApprovalDeadline'
        | 'creativeWorkflow'
    >;
    workflow: Workflow;
}

const WorkflowDeadline = (props: RenderDeadlineProps) => {
    const { workflow, activity } = props;
    const creativeType = getWorkflowCreativeType(activity, workflow._id);
    const deadline =  creativeType && getDeadlineDate(activity, workflow, creativeType);

    return (
        <>
            {deadline ? (
                <p className={getDateColor(deadline)}>
                    <Format.AsSimpleDate value={deadline} />
                </p>
            ) : (
                '-'
            )}
        </>
    )
};

export default WorkflowDeadline;