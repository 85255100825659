import type { Option } from 'smg-common';

import useReferenceData from './useReferenceData'

interface AllocatorMediaSlotsReferenceData {
    availabilitySheetOptions: Option[];
    companyOptions: Option[];
    touchpointOptions: Record<string, string>[];
}

type UseAllocatorMediaSlotsReferenceDataReturnType = [
    AllocatorMediaSlotsReferenceData,
    () => void,
];

const useAllocatorMediaSlotsReferenceData = (retailerId: string): UseAllocatorMediaSlotsReferenceDataReturnType => {
    return useReferenceData(
        `/allocator4/retailer/${retailerId}/reference-data/media-slots`,
        {
            cache: true,
            name: `allocator-reference-data-${retailerId}`,
            cacheDuration: 5 * 60 * 1000,
        }
    ) as unknown as UseAllocatorMediaSlotsReferenceDataReturnType;
}

export type {
    AllocatorMediaSlotsReferenceData,
    UseAllocatorMediaSlotsReferenceDataReturnType,
};

export default useAllocatorMediaSlotsReferenceData;