import { Button, Modal, useModal } from 'Components';

interface PlanCommentaryModalProps {
    modal: ReturnType<typeof useModal>,
    onSave: () => void,
    onCancel: () => void,
}

const PlanCommentaryModal = ({ modal, onSave, onCancel }: PlanCommentaryModalProps) => {
    return (
        <>
            <Modal.Header>Plan commentary</Modal.Header>
            <Modal.Body>
                This plan associated with this study already has evaluation commentary.
                <br /><br />
                Would you like to overwrite it?
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        modal.hide();
                        onCancel();
                    }}
                >
                    No
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        modal.hide();
                        onSave();
                    }}
                >
                    Yes
                </Button>
            </Modal.Footer>
        </>
    )
}

export default PlanCommentaryModal;