import { useState } from 'react';

import { Format, Http, ImageCarousel, Loader } from '../../../Components';

const Imagery = (props) => {
    const { className, ...restProps } = props;

    const [images, setImages] = useState([]);
    const fetchData = () => Http.get('/homepage/recent-images').then(setImages);

    const classes = [];
    if (className) classes.push(className);

    return (<Loader className={classes.join(' ').trim()} promise={fetchData} {...restProps}>
        {images?.length > 0 &&
            <div className="box-raised p-3">
                <h2 className="mb-2">Latest imagery</h2>
                <hr />
                <div className="mt-4">
                    <ImageCarousel
                        images={images.map(image => {
                            return {
                                ...image,
                                caption: (<div className="stack-col-center-center-2">
                                    <strong>{[image.description, image.touchpoint?.name].filter((part) => Boolean(part)).join(' - ')}</strong>
                                    <div>Live date: <Format.AsSimpleDate value={image.startDate} format="long" /></div>
                                </div>),
                            }
                        })}
                    />
                </div>
            </div>
        }

        {images?.length === 0 &&
            <h3 className="mt-3">You do not have any images</h3>
        }
    </Loader>);
};

export default Imagery;
