import { useState } from 'react';
import { Pagination } from 'Components';
import { BreakdownMetrics, MetricOptions } from './BreakdownView';
import { formatValue } from './chartFunctions';

interface BreakdownTableProps {
    data: BreakdownMetrics[];
    selectedMetric: MetricOptions;
    height: number;
    metricLabel: string;
    breakdownLabel: string;
}

const PAGE_SIZE = 10;

const BreakdownTable = ({ data, selectedMetric, height, metricLabel, breakdownLabel }: BreakdownTableProps) => {
    const [currentPage, setCurrentPage] = useState(1);

    return (
        <div className="table-container" style={{ maxHeight: `${height}px` }}>
            <table className="table">
                <thead>
                    <tr>
                        <th>{breakdownLabel}</th>
                        <th>{metricLabel}</th>
                        {selectedMetric === 'weightedRoiInStore' && <th>Featured SKU</th>}
                    </tr>
                </thead>
                <tbody>
                    {data
                        .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)  // Pagination
                        .map((d, i) => (
                            <tr key={i}>
                                <td>{d.label ?? 'None'}</td>
                                <td>{formatValue(
                                    selectedMetric === 'evaluatedActivities' ? d.evaluatedActivities : d[selectedMetric].value || 0,
                                    selectedMetric
                                )}</td>
                                {selectedMetric === 'weightedRoiInStore' && (
                                    <td>{formatValue(d.weightedRoiInStoreFsku.value || 0, selectedMetric)}</td>
                                )}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <Pagination
                className="mt-3"
                limit={PAGE_SIZE}
                page={currentPage}
                onChange={(page) => setCurrentPage(page)}
                total={data.length}
            />
        </div>
    )
}

export default BreakdownTable;