import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import Icon from './Icon/Icon';

interface ShowMoreLessProps {
    maxHeight: string | number;
    showMoreLabel?: string;
    showLessLabel?: string;
}

/**
 * A component that conditionally shows more or less content based on the specified maximum height.
 * It uses a `ResizeObserver` to detect when the content overflows the container.
 * Note that the content is not truncated, but hidden using `overflow: hidden`.
 *
 * @component
 * @param {PropsWithChildren<ShowMoreLessProps>} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The content to be displayed inside the component.
 * @param {number} props.maxHeight - The maximum height of the container before showing the "Show more" button.
 * @param {string} [props.showLessLabel='Show less'] - The label for the "Show less" button.
 * @param {string} [props.showMoreLabel='Show more'] - The label for the "Show more" button.
 *
 * @returns {JSX.Element} The rendered ShowMoreLess component.
 *
 * @example
 * <ShowMoreLess maxHeight={100}>
 *   <p>Your content here...</p>
 * </ShowMoreLess>
 *
 * @accessibility The "Show more" and "Show less" button is not available to screen readers as it is marked with `aria-hidden="true"`.
 */
const ShowMoreLess = (props: PropsWithChildren<ShowMoreLessProps>): JSX.Element => {
    const { children, maxHeight, showLessLabel = 'Show less', showMoreLabel = 'Show more' } = props;

    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const { current } = containerRef;

        // The callback is called whenever the element resizes
        const observer = new ResizeObserver(() => {
            // scrollHeight is the full height of the content, including the overflow
            // offsetHeight is the visible height of the container
            current && setIsOverflowing(current.scrollHeight > current.offsetHeight);
        });

        // Start observing the container
        current && observer.observe(current);

        return () => observer.disconnect();
    }, []);

    return (<>
        <div
            ref={containerRef}
            style={{
                overflow: 'hidden',
                ...!isExpanded && { maxHeight },
                ...!isExpanded && isOverflowing && { maskImage: 'linear-gradient(to bottom, black 80%, transparent)' },
            }}
        >
            {children}
        </div>
        <div aria-hidden="true" className="d-flex justify-content-end mt-3">
            {(isOverflowing || isExpanded) && (
                <button className="like-link text-bold" onClick={() => setIsExpanded((prev) => !prev)}>
                    <Icon name={isExpanded ? 'expand_less' : 'expand_more'} />
                    {isExpanded ? showLessLabel : showMoreLabel}
                </button>
            )}
        </div>
    </>);
};

export default ShowMoreLess;
