import { find } from 'lodash';
import { useState } from 'react';

import { Analytics, ColoredPanel, Format, Grid, Http, Loader, Tooltip, useAuth } from '../../../Components';

const CompanyOverview = (props) => {
    const { className, ...restProps } = props;

    const { me } = useAuth();
    const [metrics, setMetrics] = useState([]);
    const {
        activeCampaignCount,
        studies,
        totalActivitiesEvaluated,
        totalCostOfBookedMedia,
        totalCostOfPlannedMedia,
        totalCostOfStudies,
    } = metrics;

    const fetchData = () => Http.post('/homepage/panels')
        .then(setMetrics);

    const canViewEvaluations = me.permissions.viewEvaluationsStudies;
    const canViewCampaigns = me.permissions.viewCampaignsCampaigns;

    const tiles = [
        {
            name: 'Studies',
            tooltip: 'Total number of published and aggregated studies',
            value: studies,
            color: 'primary',
            linkTo: '/dashboard',
            analyticsSlug: 'STUDIES'
        },
        {
            name: 'Activities evaluated',
            tooltip: 'Total number of activities in published and aggregated studies',
            value: totalActivitiesEvaluated,
            color: 'smg-beach',
            ...(canViewEvaluations && { linkTo: '/evaluations' }),
            analyticsSlug: 'ACTIVITIES-EVALUATED'
        },
        {
            name: 'Total value of media evaluated',
            tooltip: 'Total value of media evaluated',
            value: totalCostOfStudies,
            format: 'pound',
            color: 'smg-sea',
            ...(canViewEvaluations && { linkTo: '/evaluations' }),
            analyticsSlug: 'MEDIA-EVALUATED'
        },
        {
            name: 'Active campaigns',
            tooltip: 'Total number of published campaigns',
            value: activeCampaignCount,
            color: 'smg-mud',
            ...(canViewCampaigns && { linkTo: '/planner/campaigns' }),
            analyticsSlug: 'ACTIVE-CAMPAIGNS'
        },
        {
            name: 'Total value of media booked',
            tooltip: 'Total value of media booked',
            value: totalCostOfBookedMedia,
            format: 'pound',
            color: 'smg-grape',
            ...(canViewCampaigns && { linkTo: '/planner/campaigns' }),
            analyticsSlug: 'MEDIA-BOOKED'
        },
        {
            name: 'Total value of media planned',
            tooltip: 'Total value of media planned',
            value: totalCostOfPlannedMedia,
            format: 'pound',
            color: 'danger',
            ...(canViewCampaigns && { linkTo: '/planner/campaigns' }),
            analyticsSlug: 'MEDIA-PLANNED'
        },
    ];

    const getFormat = (format, decimals, metric) => {
        if (format === 'pound') return <Format.AsCurrency value={metric} decimals={decimals} na="n/a" />
        else if (format === 'percent') return <Format.AsPercentage value={metric} decimals={decimals} />
        else return <Format.AsNumber value={metric} />;
    };

    const classes = ['box-raised', 'p-3'];
    if (className) classes.push(className);

    const handlePanelClicked = (slug) => {
        Analytics.log(`HOME-COMPANY-OVERVIEW-${slug}-CLICKED`)
    }

    return (
        <Loader className={classes.join(' ').trim()} promise={fetchData} {...restProps}>
            <h2>Company overview</h2>

            <hr />

            <Grid className="mt-3" maxColumns={2} minWidth="250px">
                {tiles.map(tile => {
                    const metric = find(metrics, { context: tile.context })?.value || tile.value;
                    const card = (<ColoredPanel key={tile.name} color={tile.color} linkTo={tile.linkTo} onClick={() => handlePanelClicked(tile.analyticsSlug)}>
                        <div className="stack-col">
                            <h3 className="text-bold">{getFormat(tile.format, tile.decimals, metric)}</h3>
                            <small>{tile.name}</small>
                        </div>
                    </ColoredPanel>);

                    return tile.tooltip
                        ? (<Tooltip hover={tile.tooltip} key={tile.name}>{card}</Tooltip>)
                        : card;
                })}
            </Grid>
        </Loader>
    );
};

export default CompanyOverview;
