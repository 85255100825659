import SummaryView from './SummaryView';

const DashboardInStorePage = () => {
    return (
        <>
            <div className="box-raised p-4 mb-3">
                <SummaryView />
            </div>
            <div className="box-raised p-4 mb-3">
                {/* Breakdown view */}
            </div>
            <div className="box-raised p-4">
                {/* Campaign breakdown table */}
            </div>
        </>
    )
}

export default DashboardInStorePage;