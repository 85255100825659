import { isFunction } from 'lodash';

import { Loading, useFetch } from 'Components';
import { HTMLProps } from 'react';

interface LoaderProps<T> extends Omit<HTMLProps<HTMLDivElement>, 'children'> {
    children: ((arg: T) => React.ReactNode) | React.ReactNode;
    dependencies?: unknown[];
    loadingLabel?: string;
    promise: () => Promise<T | undefined>;
}

function Loader<T>(props: LoaderProps<T>): React.ReactElement {
    const {
        children,
        className,
        dependencies = [],
        loadingLabel = 'Loading...',
        promise,
        ...restProps
    } = props;

    const[data, isLoading, errorMessage] = useFetch<T>(
        promise,
        dependencies,
    );

    return (
        <div className={className} {...restProps}>
            {isLoading && !errorMessage && <Loading label={loadingLabel} />}
            {errorMessage && !isLoading && <small>{errorMessage}</small>}
            {!isLoading && !errorMessage && (

                // TODO: Remove non-null assertion
                // This non-null assertion is unsafe because it assumes that the data will always be defined.
                // Created https://captureagency.atlassian.net/browse/PFX-7453 to track this issue.
                isFunction(children) ? children(data!) : children
            )}
        </div>
    );
}

export default Loader;
export {
    type LoaderProps,
}
