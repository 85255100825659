import { Route, Routes } from 'react-router-dom';

import { PageNotFound } from '../App/ErrorPages';
import { requiresAuth6 } from '../Components';
import ViewDashboardPage from './ViewDashboardPage';
import DashboardOverviewPage from './DashboardOverviewPage/DashboardOverviewPage';
import DashboardInStorePage from './DashboardInStorePage/DashboardInStorePage';

const Dashboard3Routes = () => {
    return (
        <Routes>
            <Route path="/" element={<ViewDashboardPage />}>
                <Route path="overview" element={<DashboardOverviewPage />} />
                <Route path="in-store" element={<DashboardInStorePage />} />
                <Route path="e-commerce" element={<h1>E-commerce</h1>} />
                <Route path="1pd-offsite" element={<h1>1PD offsite</h1>} />
            </Route>
            <Route path="/*" element={<PageNotFound />} />
        </Routes>
    );
};

export default requiresAuth6(Dashboard3Routes, {
    // @ts-ignore
    policies: ['beta'],
});
