export type SupportedLocaleISO = 'en-GB' | 'en-US' | 'en-IE';
export type SupportedCurrencyISO = 'GBP' | 'EUR' | 'USD';

export interface CurrencyConfig {
    name: SupportedCurrencyISO;
    symbol: string;
}

export interface LocaleConfig {
    name: SupportedLocaleISO;
    language: string; // Descriptive label
    region: string; // Descriptive label
}

export const currencies: CurrencyConfig[] = [
    {
        name: 'GBP',
        symbol: '£',
    },
    {
        name: 'EUR',
        symbol: '€',
    },
    {
        name: 'USD',
        symbol: '$',
    },
] as const;

export const locales: LocaleConfig[] = [
    {
        name: 'en-GB',
        language: 'English',
        region: 'Great Britain',
    },
    {
        name: 'en-IE',
        language: 'English',
        region: 'Ireland',
    },
    {
        name: 'en-US',
        language: 'English',
        region: 'United States',
    },
] as const;

// Used to check environment variables on PlanApps startup
export const isSupportedLocale = (name: string) => {
    return locales.filter((locale) => locale.name === name).length === 1;
};

// Used to check environment variables on PlanApps startup
export const isSupportedCurrency = (name: string) => {
    return currencies.filter((currency) => currency.name === name).length === 1;
};
