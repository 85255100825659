import { Highcharts } from 'Components';
import { Options } from 'highcharts';
import { BreakdownMetrics, MetricOptions } from './BreakdownView';
import { formatValue } from './chartFunctions';

interface BreakdownChartProps {
    data: BreakdownMetrics[];
    metricLabel: string;
    height: number;
    selectedMetric: MetricOptions;
}

const BreakdownChart = ({ data, metricLabel, height, selectedMetric }: BreakdownChartProps) => {
    return (
        <Highcharts
            options={{
                title: { text: '' },
                chart: { type: 'column', height: height },
                plotOptions: {
                    column: {
                        groupPadding: 0,
                    }
                },
                xAxis: {
                    categories: data.map(d => d.label ?? 'None')
                },
                yAxis: {
                    title: {
                        text: metricLabel
                    },
                    labels: {
                        formatter: (label) => formatValue(+label.value, selectedMetric)
                    }
                },
                tooltip: {
                    formatter: function() {
                        return [
                            `<strong>${this.series.name}</strong>`,
                            `${this.x}`,
                            `${metricLabel}: <strong>${formatValue(this.y || 0, selectedMetric)}</strong>`,
                        ].join('<br />');
                    }
                },
                // In case of weighted ROI in store, we need to show two series: one for the brand uplift and one for the featured SKU uplift
                series: selectedMetric === 'weightedRoiInStore'
                    ? [
                        {
                            type: 'column',
                            name: 'Featured SKU',
                            data: data.map(d => d.weightedRoiInStoreFsku.value || 0)
                        },
                        {
                            type: 'column',
                            name: metricLabel,
                            color: '#ffa01c',   // Stops the color changing when the breakdown is toggled
                            data: data.map(d => d.weightedRoiInStore.value || 0)
                        }
                    ]
                    : [{
                        type: 'column',
                        name: metricLabel,
                        data: data.map(d => selectedMetric === 'evaluatedActivities' ? d.evaluatedActivities : d[selectedMetric].value || 0)
                    }]
            } as Options}
        />
    )
}

export default BreakdownChart;