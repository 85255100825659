import { ReactNode } from 'react';
import css from './SummaryViewCard.module.scss';

interface SummaryViewCardProps {
    title: string,
    value: string | number,
    details?: ReactNode,
    footer?: string,
}

const SummaryViewCard = ({ title, value, details, footer }: SummaryViewCardProps) => {
    return (
        <div className={css['card']}>
            <p className={css['card-title']}>{title}</p>
            <p className={css['card-value']}>{value}</p>
            {details && details}
            {footer && (
                <div className={css['card-footer']}>
                    <p>{footer}</p>
                </div>
            )}
        </div>
    );
}

export default SummaryViewCard;