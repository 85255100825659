import AsCurrency from './AsCurrency';
import AsDate from './AsDate';
import AsHumanised from './AsHumanised';
import AsNumber from './AsNumber';
import AsPercentage from './AsPercentage';
import AsSimpleDate from './AsSimpleDate';
import AsTimeFromNow from './AsTimeFromNow';
import AsTimestamp from './AsTimestamp';

const Format = {
    AsCurrency,
    AsDate,
    AsHumanised,
    AsNumber,
    AsPercentage,
    AsSimpleDate,
    AsTimeFromNow,
    AsTimestamp,
};

export default Format;
