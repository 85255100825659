import { BreadcrumbToolbar, FilterPills, Http, Layout, NavigationTabs, PageLoader6, useAuth, useUrl6 } from 'Components'
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { simpleDate } from 'smg-common';
import { pick } from 'lodash'

import DashboardFilters from './DashboardFilters';
import DashboardUpgradeBanner from './DashboardUpgradeBanner/DashboardUpgradeBanner';

import type { FilterOptions } from './Types/DashboardFilters';

interface ViewDashboardPageContext {
    isProUser: boolean;
    queryParams: Record<string, string[]>;
}

interface ViewDashboardPageProps {
    filterOptions: FilterOptions;
}

const ViewDashboardPage = (props: ViewDashboardPageProps) => {
    const { filterOptions } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const { me } = useAuth();
    const { queryParams, setQueryParams, getQueryParamsAsString } = useUrl6();

    const currentPath = location.pathname.split('/').pop();
    const isProUser = me.policies.dashboard;

    // To avoid duplication on the UI, we only show the filter pills for filters in the ToolbarFilterPopover
    const filterPillNames = [
        { name: 'Brands', slug: 'brands' },
        { name: 'Categories', slug: 'xCategories' },
        { name: 'Is NPD', slug: 'isNpd' },
        { name: 'Is retailer event', slug: 'isRetailerEvent' },
        { name: 'Touchpoint creative', slug: 'touchpointCreative' },
        { name: 'Competition', slug: 'competitionPrize' },
    ];

    // If someone manually navigates to /dashboard3, redirect them to /dashboard3/overview
    useEffect(() => {
        if (currentPath === 'dashboard3') {
            navigate('/dashboard3/overview');
        }

        // Set initial values for filters
        if (!queryParams.retailers && filterOptions.retailers?.length > 0) {
            setQueryParams({ retailers: isProUser ? [filterOptions.retailers[0].value] : filterOptions.retailers[0].value });
        }

        const now = new Date();

        if (!queryParams.dateFrom) {
            const oneYearAgo = simpleDate.minusMonths(now, 12);
            setQueryParams({ dateFrom: simpleDate.format(oneYearAgo, 'YYYY-MM-DD') });
        }

        if (!queryParams.dateTo) {
            setQueryParams({ dateTo: simpleDate.format(now, 'YYYY-MM-DD') });
        }

        if (!queryParams.company && filterOptions.companies?.length > 0) {
            const userCompany = filterOptions.companies.find(c => c.value === String(me.company?._id));
            // Filter options should always contain the users company, but we use the first company option as a backup
            setQueryParams({ company: userCompany?.value || filterOptions.companies[0].value });
        }
    });

    const breadcrumbToolbar = (() => {
        const getBreadcrumbPath = () => {
            switch (currentPath) {
            case 'overview':
                return 'Overview';
            case 'in-store':
                return 'In-store';
            case 'e-commerce':
                return 'E-commerce';
            case '1pd-offsite':
                return '1PD offsite';
            default:
                return 'Dashboard';
            }
        }

        const breadcrumbs = [
            {
                label: 'Dashboard',
            },
            {
                label: getBreadcrumbPath(),
            }
        ];

        return (<BreadcrumbToolbar breadcrumbs={breadcrumbs} />);
    })();

    const handleFilterChange = (filter: string, value: any) => {
        if (filter === 'company') {
            // The brands filter is dependent on the company filter, so we need to clear brands when the company filter changes
            setQueryParams({ company: value, brands: undefined }, { replace: true });
        } else {
            setQueryParams({ [filter]: value });
        }
    };

    const handleFilterPillsChanged = (newFilters: any) => {
        if (!Object.keys(newFilters).length) {
            const emptyFilters = {};
            // @ts-expect-error
            filterPillNames.forEach(f => emptyFilters[f.slug] = undefined);
            setQueryParams(emptyFilters, { replace: true });
        } else {
            const filterRemoved = Object.keys(newFilters).filter((key) => !newFilters[key]);
            handleFilterChange(filterRemoved[0], undefined);
        }
    };

    return (
        <Layout pageTitle="Dashboard" toolbar={breadcrumbToolbar}>
            <DashboardUpgradeBanner
                allRetailers={filterOptions.retailers}
                isProUser={isProUser}
                retailerId={Array.isArray(queryParams.retailers) ? queryParams.retailers[0] : queryParams.retailers}
            />
            <div className="stack-center-center mb-3">
                {/* @ts-expect-error */}
                <DashboardFilters filterOptions={filterOptions} isProUser={isProUser} selectedFilters={queryParams} onFilterChange={handleFilterChange} />
            </div>
            <div className="stack-center-center mb-3">
                <FilterPills
                    filterNames={filterPillNames}
                    filterOptions={pick(filterOptions, filterPillNames.map(f => f.slug))}
                    onChange={handleFilterPillsChanged}
                    // @ts-expect-error
                    value={pick(queryParams, filterPillNames.map(f => f.slug))}
                />
            </div>
            <NavigationTabs
                className="stack-center-center mb-3"
                tabs={[
                    { label: 'Overview', linkTo: '/dashboard3/overview', query: getQueryParamsAsString() },
                    { label: 'In-store', linkTo: '/dashboard3/in-store', query: getQueryParamsAsString() },
                    { label: 'E-commerce', linkTo: '/dashboard3/e-commerce', query: getQueryParamsAsString() },
                    { label: '1PD offsite', linkTo: '/dashboard3/1pd-offsite', query: getQueryParamsAsString() },
                ]}
            />
            {/* Will render each page as a sub component depending on the current URL path */}
            <Outlet
                context={{
                    isProUser,
                    // Add currently selected filters here
                    queryParams,
                }}
            />
        </Layout>
    );
}

export {
    type ViewDashboardPageContext,
}

export default PageLoader6(ViewDashboardPage, 'Dashboard', () => ({
    filterOptions: Http.get('/dashboard3/filter-options'),
}));
