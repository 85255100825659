import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import accessibility from 'highcharts/modules/accessibility';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import noData from 'highcharts/modules/no-data-to-display';

// Make charts accessible to people with disabilities
accessibility(Highcharts);

// Needed for exporting charts
exporting(Highcharts);
// Using the default export server causes a CORS error, so we use the offline-exporting module instead.
// See: https://www.highcharts.com/docs/export-module/client-side-export
offlineExporting(Highcharts);

// No data messages
noData(Highcharts);

// Required for waterfall chart
highchartsMore(Highcharts);

// "Cross" symbol for data points
Highcharts.SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
    return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
};
if (Highcharts.VMLRenderer) {
    Highcharts.VMLRenderer.prototype.symbols.cross = Highcharts.SVGRenderer.prototype.symbols.cross;
}

Highcharts.setOptions({
    chart: {
        style: {
            fontFamily: 'Roboto'
        }
    },
    lang: { noData: 'No data to display.' },
    colors: [
        '#1ca8ff', '#ffa01c', '#0ecee8', '#99894e', '#6c4699',
        '#00272b', '#e0ff4f', '#ff6663', '#ff2ecc', '#a51080',
        '#dba507', '#0d6986', '#ff6517'

        // '#c76d7e', '#f806cc', '#7b9946', '#e0d738', '#ea7ee7',
        // '#eac07e', '#9e1d14', '#041b5e', '#422403'
    ],
    exporting: {
        fallbackToExportServer: false,
    }
});

/**
 * A very thin wrapper around Highcharts, to avoid copy-pasting the default attribution and the "export" code
 */
const HighchartsWrapper = (props) => {
    const { options, ...restProps } = props;

    const withDefaults = {
        credits: {
            text: 'Copyright © Shopper Media Group Ltd. 2017 - ' + (new Date()).getFullYear(),
            href: 'https://www.shoppermediagroup.com/',
        },
        ...options,
    };

    return (
        <HighchartsReact highcharts={Highcharts} options={withDefaults} updateArgs={[true, true]} {...restProps} />
    );
};

HighchartsWrapper.getOptions = Highcharts.getOptions;
export default HighchartsWrapper;
