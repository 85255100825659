import { createContext, useContext, FC, PropsWithChildren, useState } from 'react';
import { config } from 'smg-common';
import type { CurrencyConfig, LocaleConfig, SupportedCurrencyISO, SupportedLocaleISO } from 'smg-common';

interface LocaleContext {
    defaultLocale: SupportedLocaleISO;
    defaultCurrency: SupportedCurrencyISO;
    supportedLocales: LocaleConfig[];
    supportedCurrencies: CurrencyConfig[];
}

const defaultContext = (defaultLocale: SupportedLocaleISO = 'en-GB', defaultCurrency: SupportedCurrencyISO = 'GBP'): LocaleContext => {
    return {
        defaultLocale,
        defaultCurrency,
        supportedLocales: config.locales,
        supportedCurrencies: config.currencies,
    };
};

const Context = createContext<LocaleContext>(defaultContext());

export interface LocalProviderProps extends PropsWithChildren {
    defaultLocale: SupportedLocaleISO;
    defaultCurrency: SupportedCurrencyISO;
}

export const LocaleProvider: FC<LocalProviderProps> = ({ children, defaultCurrency, defaultLocale }) => {
    const [value] = useState<LocaleContext>(defaultContext(defaultLocale, defaultCurrency));
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useLocale = () => {
    return useContext(Context);
};
